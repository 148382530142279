import React, { useState } from 'react';
import { node, string } from 'prop-types';
import classNames from 'classnames';
import { Field } from 'react-final-form';

import css from './FieldCheckbox.module.css';

const IconCheckbox = props => {
  const { isSelected, isHovered } = props;

  return (
    <svg
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <rect
        x="0.25"
        y="0.25"
        width="14.5"
        height="14.5"
        fill={isSelected ? "var(--colorBlack)" : "none"}
        stroke={isHovered ? isSelected ? "#ACACAC" : "var(--marketplaceColorDark)" : "#ACACAC"}
        strokeWidth="0.5"
      />
    </svg>
  );
};

IconCheckbox.defaultProps = { className: null, checkedClassName: null, boxClassName: null };

IconCheckbox.propTypes = { className: string, checkedClassName: string, boxClassName: string };

const FieldCheckboxComponent = props => {
  const {
    rootClassName,
    className,
    svgClassName,
    textClassName,
    id,
    label,
    useSuccessColor,
    ...rest
  } = props;

  const classes = classNames(rootClassName || css.root, className);
  const [isHovered, setIsHovered] = useState(false);

  // This is a workaround for a bug in Firefox & React Final Form.
  // https://github.com/final-form/react-final-form/issues/134
  const handleOnChange = (input, event) => {
    const { onBlur, onChange } = input;
    onChange(event);
    onBlur(event);

    // If onChange has been passed as a props to FieldCheckbox
    if (rest.onChange) {
      rest.onChange(event);
    }
  };

  return (
    <span
      className={classes}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <Field type="checkbox" {...rest}>
        {props => {
          const { input, disabled } = props;
          const isOptSelected = input?.checked;
          const isClickable = !isOptSelected;
          const cursorMaybe = isClickable ? { cursor: 'pointer' } : { cursor: 'default' };

          return (
            <>
              <input
                id={id}
                className={css.input}
                {...input}
                onChange={event => handleOnChange(input, event)}
                disabled={disabled}
              />
              <label
                htmlFor={id}
                style={{ ...cursorMaybe }}
                className={css.label}
              >
                <span className={css.checkboxWrapper}>
                  <IconCheckbox
                    isSelected={isOptSelected}
                    isHovered={isHovered}
                  />
                </span>
                <div className={classNames(css.text, { [css.selected]: isOptSelected })}>
                  {label}
                </div>
              </label>
            </>
          );
        }}
      </Field>
    </span>
  );
};

FieldCheckboxComponent.defaultProps = {
  className: null,
  rootClassName: null,
  svgClassName: null,
  textClassName: null,
  label: null,
};

FieldCheckboxComponent.propTypes = {
  className: string,
  rootClassName: string,
  svgClassName: string,
  textClassName: string,

  // Id is needed to connect the label with input.
  id: string.isRequired,
  label: node,

  // Name groups several checkboxes to an array of selected values
  name: string.isRequired,

  // Checkbox needs a value that is passed forward when user checks the checkbox
  value: string.isRequired,
};

export default FieldCheckboxComponent;
