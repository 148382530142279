import React, { useState, useEffect, useRef } from 'react';
import { Field } from 'react-final-form';
import css from './FieldCustomSelect.module.css';
import ValidationError from '../ValidationError/ValidationError';
import { FormattedMessage } from 'react-intl';
import ArrowUp from './images/arrow-up.svg'
import ArrowDown from './images/arrow-down.svg'


const FieldCustomSelect = props => {
    const { className, name, id, label, options, placeholder, customErrorText, meta } = props;

    const FieldCustomSelect = ({ input: { onChange, onFocus, onBlur, value }, label, placeholder, customErrorText, meta, ...rest }) => {
        const wrapperRef = useRef(null);
        const [showOptions, setShowOptions] = useState(false);
        const [selectedOption, setSelectedOption] = useState(value || null);
        const [searchTerm, setSearchTerm] = useState('');

        const { valid, invalid, error, touched } = meta;
        const errorText = customErrorText || error;
        const hasError = !!customErrorText || !!(touched && invalid && error)

        const fieldMeta = { touched: hasError, error: errorText };

        // const filteredItems = searchTerm ? options?.filter(item => item?.option?.toLowerCase().includes(searchTerm?.toLowerCase()) || item?.label?.toLowerCase().includes(searchTerm?.toLowerCase())) : options;
        const filteredItems = searchTerm
            ? options?.filter(item =>
                (typeof item?.option === 'string' && item.option.toLowerCase().includes(searchTerm?.toLowerCase())) ||
                (typeof item?.label === 'string' && item.label.toLowerCase().includes(searchTerm?.toLowerCase()))
            )
            : options;
        useEffect(() => {

            const handleKeyDown = (event) => {
                if (event.key === 'Backspace') {

                    setSearchTerm(prevSearchTerm => {
                        if (prevSearchTerm !== '' && prevSearchTerm?.length > 0) {
                            return prevSearchTerm.substring(0, prevSearchTerm.length - 1)
                        } else {
                            return prevSearchTerm;
                        }
                    });
                } else if (/^[a-zA-Z0-9]$/.test(event.key)) {
                    setSearchTerm(prevSearchTerm => prevSearchTerm + event.key);
                }
            }

            function handleClickOutside(event) {
                if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
                    setShowOptions(false);
                }
            }
            document.addEventListener('mousedown', handleClickOutside);
            document.addEventListener('keydown', handleKeyDown);


            return () => {
                document.removeEventListener('mousedown', handleClickOutside);
                document.removeEventListener('keydown', handleKeyDown);
            };
        }, [wrapperRef]);


        const selectedOptionLabel =
            selectedOption ?
                <div className={css.selectedOption}>{options.find(o => o.option === selectedOption)?.label}</div>
                : <div className={css.placeholder}>{placeholder}</div>;

        return (
            <div className={className || css.wrapper}
            >

                <div className={css.inputContainer}>
                    {label && <label className={css.label}>{label}</label>}

                    <div
                        ref={wrapperRef}
                        onMouseEnter={() => wrapperRef.current.focus()}
                        tabIndex="0"
                        onClick={() => setShowOptions(!showOptions)}
                        onFocus={(event) => onFocus(event)}
                        onBlur={(event) => onBlur(event)}
                        className={css.selectInput}
                    >
                        {selectedOptionLabel}
                        <img className={css.arrow} src={showOptions ? ArrowUp : ArrowDown} />
                        {showOptions && (
                            <div className={css.options}>
                                {searchTerm &&
                                    <p className={css.searchTerm}>
                                        {searchTerm}
                                    </p>
                                }

                                {searchTerm && filteredItems.length === 0 &&
                                    <p className={css.noResults}>
                                        <FormattedMessage id="FieldCustomSelect.noResults" />
                                    </p>
                                }
                                {filteredItems?.map((o, index) => {
                                    return (
                                        <div key={index}
                                            className={css.option}
                                            onClick={() => {
                                                onChange(o.option);
                                                setSelectedOption(o.option);
                                            }}
                                        >
                                            {o.label}
                                        </div>
                                    );
                                })}
                            </div>
                        )}
                    </div>
                </div>

                <ValidationError fieldMeta={fieldMeta} />
            </div >
        );
    };

    return <Field name={name} label={label} placeholder={placeholder} id={id} component={FieldCustomSelect} {...props} />;
};
export default FieldCustomSelect;