import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconReviewStar.module.css';

const IconReviewStar = props => {
  const { className, rootClassName, isFilled } = props;
  const filledOrDefault = isFilled ? css.filled : css.root;
  const classes = classNames(rootClassName || filledOrDefault, className);

  return (
    <svg
      className={classes}
      width="19"
      height="18"
      viewBox="0 0 19 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.29961 0.313899L11.3851 6.73251L11.4069 6.79954H11.4774H18.2263L12.7663 10.7665L12.7093 10.8079L12.7311 10.8749L14.8166 17.2935L9.35663 13.3266L9.29961 13.2852L9.24259 13.3266L3.7826 17.2935L5.86813 10.8749L5.88991 10.8079L5.83289 10.7665L0.372896 6.79954H7.12182H7.1923L7.21408 6.73251L9.29961 0.313899Z"
        stroke="black"
        strokeWidth="0.194"

      />
    </svg>
  );
};

IconReviewStar.defaultProps = { className: null, rootClassName: null, isFilled: false };

const { bool, string } = PropTypes;

IconReviewStar.propTypes = {
  className: string,
  rootClassName: string,
  isFilled: bool,
};

export default IconReviewStar;
