import React, { useState, useEffect } from "react";
import { userDisplayNameAsString } from "../../util/data";
import { FormattedMessage } from '../../util/reactIntl';

import NamedLink from "../NamedLink/NamedLink";
import { AvatarMedium } from "../Avatar/Avatar";
import ReviewRating from "../ReviewRating/ReviewRating";
import { IconSpinner } from "..";

import arrowIcon from './images/chevron--down--outline 1.svg';
import locationIcon from './images/location.png';
import userIcon from './images/user.png';

import css from './UserCardWithRating.module.css';

const sharetribeSdk = require('sharetribe-flex-sdk');
const sdk = sharetribeSdk.createInstance({
    clientId: process.env.REACT_APP_SHARETRIBE_SDK_CLIENT_ID,
});

const UserCardWithRating = ({ user, authorLinkProps }) => {
    if (!user) return null;

    const [isBusy, setIsBusy] = useState(false);
    const userDisplayName = userDisplayNameAsString(user, '');
    const [userRating, setUserRating] = useState(0);

    useEffect(() => {
        const getUserRating = async () => {
            try {
                setIsBusy(true);
                let allReviews = [];
                let reviewsPage = 1;
                let reviewsTotalPages = 1;

                while (reviewsPage <= reviewsTotalPages) {
                    const reviewsResponse = await sdk.reviews.query({
                        subjectId: user.id.uuid,
                        perPage: 100,
                        page: reviewsPage,
                    });

                    const newReviews = reviewsResponse.data.data || [];
                    allReviews = [...allReviews, ...newReviews];
                    reviewsTotalPages = reviewsResponse.data.meta.totalPages;
                    reviewsPage++;
                }

                const validRatings = allReviews
                    .map(review => review.attributes.rating)
                    .filter(rating => rating !== null);

                const averageRating = validRatings.length > 0
                    ? validRatings.reduce((sum, rating) => sum + rating, 0) / validRatings.length
                    : 0; // If no valid ratings, return 0

                setUserRating(averageRating);
            } catch (error) {
                console.error('Error fetching user listings:', error);
            } finally {
                setIsBusy(false);
            }
        };
        getUserRating();
    }, []);

    const createdAt = new Date(user.attributes.createdAt);
    const formattedDate = createdAt.toLocaleDateString("en-US", {
        month: "long",
        year: "numeric",
    });
    const location = user?.attributes?.profile?.publicData?.location?.address || '';

    return (
        <div className={css.root}>
            <div className={css.container}>
                <AvatarMedium user={user} className={css.providerAvatar} />
                <div className={css.userName}>
                    {userDisplayName}
                    {isBusy ? <IconSpinner /> :
                        <ReviewRating
                            rating={userRating}
                            className={css.reviewRating}
                            reviewStarClassName={css.reviewRatingStar}
                        />
                    }
                </div>
                {authorLinkProps &&
                    <NamedLink {...authorLinkProps} className={css.link}>
                        <img src={arrowIcon} alt="arrow" />
                    </NamedLink>
                }
            </div>
            <div className={css.info}>
                <span className={css.item}>
                    <img src={locationIcon} />
                    <FormattedMessage id="UserCardWithRating.location" values={{ location: location }} />
                </span>
                <span className={css.item}>
                    <img src={userIcon} />
                    <FormattedMessage id="UserCardWithRating.joined" values={{ date: formattedDate }} />
                </span>
            </div>
        </div>
    );
};

export default UserCardWithRating;