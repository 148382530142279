import React, { useState, useEffect, useRef } from 'react';
import { Field } from 'react-final-form';
import { FormattedMessage } from 'react-intl';
import ValidationError from '../ValidationError/ValidationError';

import ArrowUp from './images/arrow-up.svg';
import ArrowDown from './images/arrow-down.svg';
import deleteIcon from './images/close.png';

import css from './FieldCustomMultipleSelect.module.css';

const FieldCustomMultipleSelect = props => {
    const { className, name, id, label, options, placeholder, customErrorText, meta } = props;

    const FieldCustomSelectInner = ({ input: { onChange, onFocus, onBlur, value }, label, placeholder, customErrorText, meta, ...rest }) => {
        const wrapperRef = useRef(null);
        const [showOptions, setShowOptions] = useState(false);
        const [searchTerm, setSearchTerm] = useState('');
        const [selectedOptions, setSelectedOptions] = useState(Array.isArray(value) ? value : []);

        const { valid, invalid, error, touched } = meta;
        const errorText = customErrorText || error;
        const hasError = !!customErrorText || !!(touched && invalid && error)
        const fieldMeta = { touched: hasError, error: errorText };

        useEffect(() => {
            const handleKeyDown = (event) => {
                if (event.key === 'Backspace') {
                    setSearchTerm(prev => prev.slice(0, -1));
                } else if (/^[a-zA-Z0-9]$/.test(event.key)) {
                    setSearchTerm(prev => prev + event.key);
                }
            };

            const handleClickOutside = (event) => {
                if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
                    setShowOptions(false);
                }
            };

            document.addEventListener('mousedown', handleClickOutside);
            document.addEventListener('keydown', handleKeyDown);

            return () => {
                document.removeEventListener('mousedown', handleClickOutside);
                document.removeEventListener('keydown', handleKeyDown);
            };
        }, []);

        const filteredItems = searchTerm
            ? options?.filter(item =>
                (typeof item?.option === 'string' && item.option.toLowerCase().includes(searchTerm?.toLowerCase())) ||
                (typeof item?.label === 'string' && item.label.toLowerCase().includes(searchTerm?.toLowerCase()))
            )
            : options;

        const handleSelectOption = (option) => {
            if (!selectedOptions.includes(option)) {
                const newValues = [...selectedOptions, option];
                setSelectedOptions(newValues);
                onChange(newValues);
            }
        };

        const handleRemoveOption = (optionToRemove) => {
            const updated = selectedOptions.filter(o => o !== optionToRemove);
            setSelectedOptions(updated);
            onChange(updated);
        };

        return (
            <div className={css.wrapper}>
                <div className={css.inputContainer}>
                    {label && <label className={css.label}>{label}</label>}
                    <div
                        ref={wrapperRef}
                        tabIndex="0"
                        onClick={() => setShowOptions(!showOptions)}
                        onFocus={onFocus}
                        onBlur={onBlur}
                        className={css.selectInput}
                    >
                        {selectedOptions.length > 0 ? (
                            <div className={css.selectedTags}>
                                {selectedOptions.map((opt) => {
                                    const item = options.find(o => o.option === opt);
                                    return (
                                        <div key={opt} className={css.tag} >
                                            <span style={item?.hex ? { backgroundColor: item.hex } : { padding: 0 }}>
                                                {item?.label}
                                            </span>
                                            <img
                                                src={deleteIcon}
                                                className={css.removeBtn}
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    handleRemoveOption(opt);
                                                }}
                                            />
                                        </div>
                                    );
                                })}
                            </div>
                        ) : (
                            <div className={css.placeholder}>{placeholder}</div>
                        )}

                        <img className={css.arrow} src={showOptions ? ArrowUp : ArrowDown} />

                        {showOptions && (
                            <div className={css.options}>
                                {searchTerm && <p className={css.searchTerm}>{searchTerm}</p>}
                                {searchTerm && filteredItems.length === 0 && (
                                    <p className={css.noResults}>
                                        <FormattedMessage id="FieldCustomSelect.noResults" />
                                    </p>
                                )}
                                {filteredItems?.map((o, index) => {
                                    return (
                                        <div
                                            key={index}
                                            className={css.option}
                                            style={o.hex ? { backgroundColor: o.hex } : null}
                                            onClick={() => handleSelectOption(o.option)}
                                        >
                                            {o.label}
                                        </div>
                                    );
                                })}
                            </div>
                        )}
                    </div>
                </div>
                <ValidationError fieldMeta={fieldMeta} />
            </div>
        );
    };

    return <Field name={name} label={label} placeholder={placeholder} id={id} component={FieldCustomSelectInner} {...props} />;
};
export default FieldCustomMultipleSelect;