import React, { useState } from 'react';
import { arrayOf, shape, string } from 'prop-types';
import { Field } from 'react-final-form';
import classNames from 'classnames';
import { ValidationError } from '../../components';

import css from './FieldSelectParcelSize.module.css';

const RoundIconCheckbox = props => {
  const { isSelected, isHovered } = props;

  return (
    <div className={css.icon}    >
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g>
          <path
            d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
            stroke={(isSelected && isHovered) ? "#808080" : isHovered ? "var(--marketplaceColorDark)" : isSelected ? "var(--colorBlack)" : "#808080"}
            strokeWidth="1.5"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
            fill={isSelected ? "var(--colorBlack)" : "none"}
          />
        </g>
      </svg>
    </div>
  );
};

RoundIconCheckbox.defaultProps = { className: null, checkedClassName: null, boxClassName: null };

RoundIconCheckbox.propTypes = { className: string, checkedClassName: string, boxClassName: string };

const Option = props => {
  const { option, selectedValue, onChange } = props;
  const { key: optionKey, label, details } = option;
  const isOptSelected = selectedValue === optionKey;
  const isClickable = !isOptSelected;
  const cursorMaybe = isClickable ? { cursor: 'pointer' } : { cursor: 'default' };
  const [isHovered, setIsHovered] = useState(false);

  return (
    <li
      className={css.option}
      style={{ ...cursorMaybe }}
      onClick={e => {
        e.preventDefault();
        e.stopPropagation();
        if (isClickable) {
          onChange(optionKey);
        }
      }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <button
        className={css.optionBtn}

      >
        <div className={css.info}>
          {label}
          <span className={css.details}>{details}</span>
        </div>
        <RoundIconCheckbox isSelected={isOptSelected} isHovered={isHovered} />
      </button>
    </li>
  );
};

const OptionList = props => {
  const { hasOptions, children } = props;
  return hasOptions ? <ul className={css.optionList}>{children}</ul> : null;
};

const SelectOptions = props => {
  const { options, ...rest } = props;
  return (
    <OptionList hasOptions={options?.length > 0}>
      {options.map(option => (
        <Option key={option.key} option={option} {...rest} />
      ))}
    </OptionList>
  );
};

const FieldSelectParcelSize = props => {
  const {
    className,
    rootClassName,
    label,
    name,
    options,
    id,
    isColumnOnly,
    ...rest } = props;
  const classes = classNames(rootClassName || css.root, className);

  if (!options) return null;

  return (
    <Field name={name} id={id} {...rest}>
      {fieldProps => {
        const { value, onChange } = fieldProps?.input || {};
        const meta = fieldProps?.meta;

        return (
          <div className={classes}>
            {isColumnOnly ?
              <>
                {label ? <label className={css.label} htmlFor={id}>{label}</label> : null}
                <SelectOptions
                  options={options}
                  selectedValue={value}
                  onChange={onChange}
                />
              </>
              :
              <div className={css.inputContainer}>
                {label ? <label className={css.label} htmlFor={id}>{label}</label> : null}
                <SelectOptions
                  options={options}
                  selectedValue={value}
                  onChange={onChange}
                />
              </div>
            }
            <ValidationError fieldMeta={meta} />
          </div>
        );
      }}
    </Field>
  );
};

FieldSelectParcelSize.defaultProps = {
  rootClassName: null,
  className: null,
};

FieldSelectParcelSize.propTypes = {
  className: string,
  rootClassName: string,
  label: string,
  name: string.isRequired,
  options: arrayOf(
    shape({
      key: string.isRequired,
      label: string.isRequired,
      details: string,
    })
  ).isRequired,
};

export default FieldSelectParcelSize;
