import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { storableError } from '../../util/errors';
import { createImageVariantConfig } from '../../util/sdkLoader';
export const ASSET_NAME = 'landing-page';

// ================ Action types ================ //

export const SET_INITIAL_STATE = 'app/LandingPage/SET_INITIAL_STATE';

export const QUERY_RECENT_LISTINGS_REQUEST = 'app/LandingPage/QUERY_RECENT_LISTINGS_REQUEST';
export const QUERY_RECENT_LISTINGS_SUCCESS = 'app/LandingPage/QUERY_RECENT_LISTINGS_SUCCESS';
export const QUERY_RECENT_LISTINGS_ERROR = 'app/LandingPage/QUERY_RECENT_LISTINGS_ERROR';

export const QUERY_SALE_LISTINGS_REQUEST = 'app/LandingPage/QUERY_SALE_LISTINGS_REQUEST';
export const QUERY_SALE_LISTINGS_SUCCESS = 'app/LandingPage/QUERY_SALE_LISTINGS_SUCCESS';
export const QUERY_SALE_LISTINGS_ERROR = 'app/LandingPage/QUERY_SALE_LISTINGS_ERROR';

export const QUERY_TRENDING_LISTINGS_REQUEST = 'app/LandingPage/QUERY_TRENDING_LISTINGS_REQUEST';
export const QUERY_TRENDING_LISTINGS_SUCCESS = 'app/LandingPage/QUERY_TRENDING_LISTINGS_SUCCESS';
export const QUERY_TRENDING_LISTINGS_ERROR = 'app/LandingPage/QUERY_TRENDING_LISTINGS_ERROR';

export const QUERY_FOOTWEAR_LISTINGS_REQUEST = 'app/LandingPage/QUERY_FOOTWEAR_LISTINGS_REQUEST';
export const QUERY_FOOTWEAR_LISTINGS_SUCCESS = 'app/LandingPage/QUERY_FOOTWEAR_LISTINGS_SUCCESS';
export const QUERY_FOOTWEAR_LISTINGS_ERROR = 'app/LandingPage/QUERY_FOOTWEAR_LISTINGS_ERROR';
// ================ Reducer ================ //

const initialState = {
  recentListings: null,
  queryRecentListingsError: null,
  queryRecentListingsInProgress: false,
  saleListings: null,
  querySaleListingsError: null,
  querySaleListingsInProgress: false,
  trendingListings: null,
  queryTrendingListingsError: null,
  queryTrendingListingsInProgress: false,
  footwearListings: null,
  queryFootwearListingsInProgress: null,
  queryFootwearListingsError: false,
};

export default function profilePageReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case SET_INITIAL_STATE:
      return { ...initialState };

    case QUERY_RECENT_LISTINGS_REQUEST:
      return {
        ...state,
        recentListings: null,
        queryRecentListingsError: null,
        queryRecentListingsInProgress: true,
      };
    case QUERY_RECENT_LISTINGS_SUCCESS:
      return { ...state, recentListings: payload.listings, queryRecentListingsInProgress: false };
    case QUERY_RECENT_LISTINGS_ERROR:
      return { ...state, recentListings: null, queryRecentListingsError: payload, queryRecentListingsInProgress: false };

    case QUERY_SALE_LISTINGS_REQUEST:
      return {
        ...state,
        saleListings: null,
        querySaleListingsError: null,
        querySaleListingsInProgress: true,
      };
    case QUERY_SALE_LISTINGS_SUCCESS:
      return { ...state, saleListings: payload.listings, querySaleListingsInProgress: false };
    case QUERY_SALE_LISTINGS_ERROR:
      return { ...state, saleListings: null, querySaleListingsError: payload, querySaleListingsInProgress: false };

    case QUERY_TRENDING_LISTINGS_REQUEST:
      return {
        ...state,
        trendingListings: null,
        queryTrendingListingsError: null,
        queryTrendingListingsInProgress: true,
      };
    case QUERY_TRENDING_LISTINGS_SUCCESS:
      return { ...state, trendingListings: payload.listings, queryTrendingListingsInProgress: false };
    case QUERY_TRENDING_LISTINGS_ERROR:
      return { ...state, trendingListings: null, queryTrendingListingsError: payload, queryTrendingListingsInProgress: false };

    case QUERY_FOOTWEAR_LISTINGS_REQUEST:
      return {
        ...state,
        footwearListings: null,
        queryFootwearListingsError: null,
        queryFootwearListingsInProgress: true,
      };
    case QUERY_FOOTWEAR_LISTINGS_SUCCESS:
      return { ...state, footwearListings: payload.listings, queryFootwearListingsInProgress: false };
    case QUERY_FOOTWEAR_LISTINGS_ERROR:
      return { ...state, footwearListings: null, queryFootwearListingsError: payload, queryFootwearListingsInProgress: false };

    default:
      return state;
  }
}

// ================ Action creators ================ //

export const setInitialState = () => ({
  type: SET_INITIAL_STATE,
});


export const queryRecentListingsRequest = userId => ({
  type: QUERY_RECENT_LISTINGS_REQUEST,
  payload: { userId },
});

export const queryRecentListingsSuccess = listings => ({
  type: QUERY_RECENT_LISTINGS_SUCCESS,
  payload: { listings },
});

export const queryRecentListingsError = e => ({
  type: QUERY_RECENT_LISTINGS_ERROR,
  error: true,
  payload: e,
});

export const querySaleListingsRequest = userId => ({
  type: QUERY_SALE_LISTINGS_REQUEST,
  payload: { userId },
});

export const querySaleListingsSuccess = listings => ({
  type: QUERY_SALE_LISTINGS_SUCCESS,
  payload: { listings },
});

export const querySaleListingsError = e => ({
  type: QUERY_SALE_LISTINGS_ERROR,
  error: true,
  payload: e,
});

export const queryTrendingListingsRequest = userId => ({
  type: QUERY_TRENDING_LISTINGS_REQUEST,
  payload: { userId },
});

export const queryTrendingListingsSuccess = listings => ({
  type: QUERY_TRENDING_LISTINGS_SUCCESS,
  payload: { listings },
});

export const queryTrendingListingsError = e => ({
  type: QUERY_TRENDING_LISTINGS_ERROR,
  error: true,
  payload: e,
});

export const queryFootwearListingsRequest = userId => ({
  type: QUERY_FOOTWEAR_LISTINGS_REQUEST,
  payload: { userId },
});

export const queryFootwearListingsSuccess = listings => ({
  type: QUERY_FOOTWEAR_LISTINGS_SUCCESS,
  payload: { listings },
});

export const queryFootwearListingsError = e => ({
  type: QUERY_FOOTWEAR_LISTINGS_ERROR,
  error: true,
  payload: e,
});
// ================ Thunks ================ //


export const queryRecentListings = (config) => (dispatch, getState, sdk) => {
  dispatch(queryRecentListingsRequest());

  const {
    aspectWidth = 1,
    aspectHeight = 1,
    variantPrefix = 'listing-card',
  } = config.layout.listingImage;
  const aspectRatio = aspectHeight / aspectWidth;

  return sdk.listings
    .query({
      page: 1,
      perPage: 6,
      include: ['author', 'images'],
      'fields.image': [`variants.${variantPrefix}`, `variants.${variantPrefix}-2x`],
      ...createImageVariantConfig(`${variantPrefix}`, 400, aspectRatio),
      ...createImageVariantConfig(`${variantPrefix}-2x`, 800, aspectRatio),
    })
    .then(response => {
      dispatch(addMarketplaceEntities(response));

      const imagesArray = response.data.included.filter(i => {
        return i.type === 'image';
      });

      const listingsWithImages = response.data?.data.map(l => {

        const listingImageId = l.relationships.images?.data[0]?.id?.uuid;

        if (!listingImageId) {
          return l;
        }

        const listingImage = imagesArray.find(i => i.id.uuid === listingImageId);
        if (listingImage) {
          l.images = [listingImage];
        }

        return l;
      })

      dispatch(queryRecentListingsSuccess(listingsWithImages));
      return response;
    })
    .catch(e => dispatch(queryRecentListingsError(storableError(e))));
};

export const querySaleListings = (config) => (dispatch, getState, sdk) => {
  dispatch(querySaleListingsRequest());
  const {
    aspectWidth = 1,
    aspectHeight = 1,
    variantPrefix = 'listing-card',
  } = config.layout.listingImage;
  const aspectRatio = aspectHeight / aspectWidth;

  return sdk.listings
    .query({
      page: 1,
      perPage: 6,
      include: ['author', 'images'],
      pub_hasDiscount: true,
      'fields.image': [`variants.${variantPrefix}`, `variants.${variantPrefix}-2x`],
      ...createImageVariantConfig(`${variantPrefix}`, 400, aspectRatio),
      ...createImageVariantConfig(`${variantPrefix}-2x`, 800, aspectRatio),
    })
    .then(response => {
      dispatch(addMarketplaceEntities(response));

      const imagesArray = response.data.included.filter(i => {
        return i.type === 'image';
      });

      const listingsWithImages = response.data?.data.map(l => {

        const listingImageId = l.relationships.images?.data[0]?.id?.uuid;

        if (!listingImageId) {
          return l;
        }

        const listingImage = imagesArray.find(i => i.id.uuid === listingImageId);
        if (listingImage) {
          l.images = [listingImage];
        }

        return l;
      })

      dispatch(querySaleListingsSuccess(listingsWithImages));
      return response;
    })
    .catch(e => dispatch(querySaleListingsError(storableError(e))));
};

export const queryTrendingListings = (config) => (dispatch, getState, sdk) => {
  dispatch(queryTrendingListingsRequest());
  const {
    aspectWidth = 1,
    aspectHeight = 1,
    variantPrefix = 'listing-card',
  } = config.layout.listingImage;
  const aspectRatio = aspectHeight / aspectWidth;

  return sdk.listings
    .query({
      page: 1,
      perPage: 8,
      include: ['author', 'images'],
      pub_isTopTrending: true,
      'fields.image': [`variants.${variantPrefix}`, `variants.${variantPrefix}-2x`],
      ...createImageVariantConfig(`${variantPrefix}`, 400, aspectRatio),
      ...createImageVariantConfig(`${variantPrefix}-2x`, 800, aspectRatio),
    })
    .then(response => {
      dispatch(addMarketplaceEntities(response));

      const imagesArray = response.data.included.filter(i => {
        return i.type === 'image';
      });

      const listingsWithImages = response.data?.data.map(l => {

        const listingImageId = l.relationships.images?.data[0]?.id?.uuid;

        if (!listingImageId) {
          return l;
        }

        const listingImage = imagesArray.find(i => i.id.uuid === listingImageId);
        if (listingImage) {
          l.images = [listingImage];
        }

        return l;
      })

      dispatch(queryTrendingListingsSuccess(listingsWithImages));
      return response;
    })
    .catch(e => dispatch(queryTrendingListingsError(storableError(e))));
};

export const queryFootwearListings = (config) => (dispatch, getState, sdk) => {
  dispatch(queryFootwearListingsRequest());
  const {
    aspectWidth = 1,
    aspectHeight = 1,
    variantPrefix = 'listing-card',
  } = config.layout.listingImage;
  const aspectRatio = aspectHeight / aspectWidth;

  return sdk.listings
    .query({
      page: 1,
      perPage: 8,
      include: ['author', 'images'],
      pub_productCategory: "Footwear",
      'fields.image': [`variants.${variantPrefix}`, `variants.${variantPrefix}-2x`],
      ...createImageVariantConfig(`${variantPrefix}`, 400, aspectRatio),
      ...createImageVariantConfig(`${variantPrefix}-2x`, 800, aspectRatio),
    })
    .then(response => {
      dispatch(addMarketplaceEntities(response));

      const imagesArray = response.data.included.filter(i => {
        return i.type === 'image';
      });

      const listingsWithImages = response.data?.data.map(l => {

        const listingImageId = l.relationships.images?.data[0]?.id?.uuid;

        if (!listingImageId) {
          return l;
        }

        const listingImage = imagesArray.find(i => i.id.uuid === listingImageId);
        if (listingImage) {
          l.images = [listingImage];
        }

        return l;
      })

      dispatch(queryFootwearListingsSuccess(listingsWithImages));
      return response;
    })
    .catch(e => dispatch(queryFootwearListingsError(storableError(e))));
};