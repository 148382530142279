export const productTypes = [
    { type: "Menswear", category: "Tops", subCategory: "T-shirts " },
    { type: "Menswear", category: "Tops", subCategory: "Casual Shirts" },
    { type: "Menswear", category: "Tops", subCategory: "Hoodies " },
    { type: "Menswear", category: "Tops", subCategory: "Jumpers" },
    { type: "Menswear", category: "Tops", subCategory: "Dress Shirts" },
    { type: "Menswear", category: "Tops", subCategory: "Polo Shirts " },
    { type: "Menswear", category: "Tops", subCategory: "Sweat Shirts" },
    { type: "Menswear", category: "Tops", subCategory: "Tank Top " },

    { type: "Menswear", category: "Bottoms", subCategory: "T-shirts " },
    { type: "Menswear", category: "Bottoms", subCategory: "Sweatpants" },
    { type: "Menswear", category: "Bottoms", subCategory: "Casual Pants" },
    { type: "Menswear", category: "Bottoms", subCategory: "Dress Pants" },
    { type: "Menswear", category: "Bottoms", subCategory: "Jeans" },
    { type: "Menswear", category: "Bottoms", subCategory: "Shorts" },

    { type: "Menswear", category: "Underwear", subCategory: "Boxers" },
    { type: "Menswear", category: "Underwear", subCategory: "Breifs" },

    { type: "Menswear", category: "Outerwear", subCategory: "Blazers" },
    { type: "Menswear", category: "Outerwear", subCategory: "Bomber Jackets " },
    { type: "Menswear", category: "Outerwear", subCategory: "Denim Jackets " },
    { type: "Menswear", category: "Outerwear", subCategory: "Leather Jackets" },
    { type: "Menswear", category: "Outerwear", subCategory: "Windbreakers" },
    { type: "Menswear", category: "Outerwear", subCategory: "Puffer Jackets " },
    { type: "Menswear", category: "Outerwear", subCategory: "Suits " },
    { type: "Menswear", category: "Outerwear", subCategory: "Fleeces" },
    { type: "Menswear", category: "Outerwear", subCategory: "Snow Suits" },
    { type: "Menswear", category: "Outerwear", subCategory: "Casual Shoes" },
    { type: "Menswear", category: "Outerwear", subCategory: "Dress Shoes" },
    { type: "Menswear", category: "Outerwear", subCategory: "Flip Flops & Slides" },
    { type: "Menswear", category: "Outerwear", subCategory: "Sneakers" },
    { type: "Menswear", category: "Outerwear", subCategory: "Boots " },
    { type: "Menswear", category: "Outerwear", subCategory: "Brogues" },
    { type: "Menswear", category: "Outerwear", subCategory: "Chelsea Boots" },
    { type: "Menswear", category: "Outerwear", subCategory: "Loafers" },

    { type: "Menswear", category: "Footwear", subCategory: "Blazers" },
    { type: "Menswear", category: "Footwear", subCategory: "Casual Shoess" },
    { type: "Menswear", category: "Footwear", subCategory: "Dress Shoess" },
    { type: "Menswear", category: "Footwear", subCategory: "Flip Flops & Slidess" },
    { type: "Menswear", category: "Footwear", subCategory: "Sneakerss" },
    { type: "Menswear", category: "Footwear", subCategory: "Bootss" },
    { type: "Menswear", category: "Footwear", subCategory: "Broguess" },
    { type: "Menswear", category: "Footwear", subCategory: "Chelsea Bootss" },
    { type: "Menswear", category: "Footwear", subCategory: "Loaferss" },

    { type: "Menswear", category: "Swimwear ", subCategory: "Swim Shorts" },

    { type: "Womenswear", category: "Tops", subCategory: "Blouses" },
    { type: "Womenswear", category: "Tops", subCategory: "Blouses" },
    { type: "Womenswear", category: "Tops", subCategory: "Cardigans" },
    { type: "Womenswear", category: "Tops", subCategory: "Hoodies" },
    { type: "Womenswear", category: "Tops", subCategory: "Jumpers" },
    { type: "Womenswear", category: "Tops", subCategory: "Vests" },
    { type: "Womenswear", category: "Tops", subCategory: "Bandeau Tops" },
    { type: "Womenswear", category: "Tops", subCategory: "Body Suits" },
    { type: "Womenswear", category: "Tops", subCategory: "Bralets" },
    { type: "Womenswear", category: "Tops", subCategory: "Cami Tops" },
    { type: "Womenswear", category: "Tops", subCategory: "Crop Tops" },
    { type: "Womenswear", category: "Tops", subCategory: "T-Shirts" },
    { type: "Womenswear", category: "Tops", subCategory: "Shirts" },

    { type: "Womenswear", category: "Bottoms", subCategory: "Jeans", secondarySubCategory: "Boyfriend Jeans" },
    { type: "Womenswear", category: "Bottoms", subCategory: "Jeans", secondarySubCategory: "Skinny Jeans" },
    { type: "Womenswear", category: "Bottoms", subCategory: "Jeans", secondarySubCategory: "Ripped Jeans" },
    { type: "Womenswear", category: "Bottoms", subCategory: "Jeans", secondarySubCategory: "Wide Leg Jeans" },
    { type: "Womenswear", category: "Bottoms", subCategory: "Jeans", secondarySubCategory: "Bootcut Jeans" },
    { type: "Womenswear", category: "Bottoms", subCategory: "Jeans", secondarySubCategory: "Flare Jeans" },
    { type: "Womenswear", category: "Bottoms", subCategory: "Jeans", secondarySubCategory: "High Wasted Jeans" },
    { type: "Womenswear", category: "Bottoms", subCategory: "Jeans", secondarySubCategory: "Mom Jeans" },

    { type: "Womenswear", category: "Bottoms", subCategory: "Skirts", secondarySubCategory: "Mini Skirts" },
    { type: "Womenswear", category: "Bottoms", subCategory: "Skirts", secondarySubCategory: "Maxi Skirts" },
    { type: "Womenswear", category: "Bottoms", subCategory: "Skirts", secondarySubCategory: "Pencil Skirts" },
    { type: "Womenswear", category: "Bottoms", subCategory: "Skirts", secondarySubCategory: "Pleated Skirts" },

    { type: "Womenswear", category: "Bottoms", subCategory: "Jeggings" },
    { type: "Womenswear", category: "Bottoms", subCategory: "Leggings" },
    { type: "Womenswear", category: "Bottoms", subCategory: "Casual Trousers" },
    { type: "Womenswear", category: "Bottoms", subCategory: "Dress Pants" },
    { type: "Womenswear", category: "Bottoms", subCategory: "Playsuits" },
    { type: "Womenswear", category: "Bottoms", subCategory: "Shorts" },

    { type: "Womenswear", category: "Dresses", subCategory: "Bodycon Dresses" },
    { type: "Womenswear", category: "Dresses", subCategory: "Babydoll Dresses" },
    { type: "Womenswear", category: "Dresses", subCategory: "Casual Dresses" },
    { type: "Womenswear", category: "Dresses", subCategory: "Evening Dresses" },
    { type: "Womenswear", category: "Dresses", subCategory: "Jumpsuits" },
    { type: "Womenswear", category: "Dresses", subCategory: "Midi Dresses" },
    { type: "Womenswear", category: "Dresses", subCategory: "Prom Dresses" },
    { type: "Womenswear", category: "Dresses", subCategory: "Rompers" },
    { type: "Womenswear", category: "Dresses", subCategory: "Summer Dresses" },
    { type: "Womenswear", category: "Dresses", subCategory: "Maxi Dresses" },
    { type: "Womenswear", category: "Dresses", subCategory: "Suits" },
    { type: "Womenswear", category: "Dresses", subCategory: "Shirt Dresses" },
    { type: "Womenswear", category: "Dresses", subCategory: "Wrap Dresses" },

    { type: "Womenswear", category: "Lingerie", subCategory: "Pyjamas" },
    { type: "Womenswear", category: "Lingerie", subCategory: "Robes" },
    { type: "Womenswear", category: "Lingerie", subCategory: "Bras" },
    { type: "Womenswear", category: "Lingerie", subCategory: "Camisoles" },
    { type: "Womenswear", category: "Lingerie", subCategory: "Night Gowns" },
    { type: "Womenswear", category: "Lingerie", subCategory: "Underwear" },

    { type: "Womenswear", category: "Outerwear", subCategory: "Blazers" },
    { type: "Womenswear", category: "Outerwear", subCategory: "Bomber Jackets" },
    { type: "Womenswear", category: "Outerwear", subCategory: "Demin Jackets" },
    { type: "Womenswear", category: "Outerwear", subCategory: "Kimonos" },
    { type: "Womenswear", category: "Outerwear", subCategory: "Puffer Jackets" },
    { type: "Womenswear", category: "Outerwear", subCategory: "Faux Fur Coats" },
    { type: "Womenswear", category: "Outerwear", subCategory: "Capes & Ponchos" },
    { type: "Womenswear", category: "Outerwear", subCategory: "Leather Jackets" },
    { type: "Womenswear", category: "Outerwear", subCategory: "Track Jackets" },
    { type: "Womenswear", category: "Outerwear", subCategory: "Windbreakers" },
    { type: "Womenswear", category: "Outerwear", subCategory: "Fleeces" },
    { type: "Womenswear", category: "Outerwear", subCategory: "Snow Suits" },

    { type: "Womenswear", category: "Footwear", subCategory: "Boots" },
    { type: "Womenswear", category: "Footwear", subCategory: "Loafers" },
    { type: "Womenswear", category: "Footwear", subCategory: "Sandals" },
    { type: "Womenswear", category: "Footwear", subCategory: "Slides" },
    { type: "Womenswear", category: "Footwear", subCategory: "Trainers" },
    { type: "Womenswear", category: "Footwear", subCategory: "Flats" },
    { type: "Womenswear", category: "Footwear", subCategory: "Heels" },
    { type: "Womenswear", category: "Footwear", subCategory: "Knee High Boots" },
    { type: "Womenswear", category: "Footwear", subCategory: "Platforms" },
    { type: "Womenswear", category: "Footwear", subCategory: "Wedges" },
    { type: "Womenswear", category: "Footwear", subCategory: "Ankle Boots" },
    { type: "Womenswear", category: "Footwear", subCategory: "Brogues" },
    { type: "Womenswear", category: "Footwear", subCategory: "Chelsea Boots" },
    { type: "Womenswear", category: "Footwear", subCategory: "Over The Knee Boots" },

    { type: "Womenswear", category: "Swimwear", subCategory: "One Piece" },
    { type: "Womenswear", category: "Swimwear", subCategory: "Two Piece" },
    { type: "Womenswear", category: "Swimwear", subCategory: "Kaftans" },


    { type: "Kids", category: "Boys", subCategory: "Tops" },
    { type: "Kids", category: "Boys", subCategory: "Bottoms" },
    { type: "Kids", category: "Boys", subCategory: "Coats & Jackets" },
    { type: "Kids", category: "Boys", subCategory: "Suits" },
    { type: "Kids", category: "Boys", subCategory: "Footwear" },
    { type: "Kids", category: "Boys", subCategory: "Accessories" },
    { type: "Kids", category: "Boys", subCategory: "Nightwear" },
    { type: "Kids", category: "Boys", subCategory: "Swimwear" },
    { type: "Kids", category: "Boys", subCategory: "Fancy Dress" },
    { type: "Kids", category: "Boys", subCategory: "Sleep suits" },
    { type: "Kids", category: "Boys", subCategory: "Swimwear" },

    { type: "Kids", category: "Girls", subCategory: "Tops" },
    { type: "Kids", category: "Girls", subCategory: "Bottoms" },
    { type: "Kids", category: "Girls", subCategory: "Coats & Jackets" },
    { type: "Kids", category: "Girls", subCategory: "Suits" },
    { type: "Kids", category: "Girls", subCategory: "Dresses" },
    { type: "Kids", category: "Girls", subCategory: "Jumpsuits & Play Suits" },
    { type: "Kids", category: "Girls", subCategory: "Footwear" },
    { type: "Kids", category: "Girls", subCategory: "Accessories" },
    { type: "Kids", category: "Girls", subCategory: "Nightwear" },
    { type: "Kids", category: "Girls", subCategory: "Swimwear" },
    { type: "Kids", category: "Girls", subCategory: "Fancy Dress" },
    { type: "Kids", category: "Girls", subCategory: "Sleep suits" },
    { type: "Kids", category: "Girls", subCategory: "Swimwear" },

    { type: "Kids", category: "Baby", subCategory: "Bodysuits" },
    { type: "Kids", category: "Baby", subCategory: "Onsies" },
    { type: "Kids", category: "Baby", subCategory: "Sleepwear" },
    { type: "Kids", category: "Baby", subCategory: "Tops" },
    { type: "Kids", category: "Baby", subCategory: "Bottoms" },
    { type: "Kids", category: "Baby", subCategory: "Dresses" },
    { type: "Kids", category: "Baby", subCategory: "Matching sets" },
    { type: "Kids", category: "Baby", subCategory: "Outerwear" },
    { type: "Kids", category: "Baby", subCategory: "Swimwear" },

    { type: "Accesories", category: "Mens Accessories ", subCategory: "Belts" },
    { type: "Accesories", category: "Mens Accessories ", subCategory: "Gloves" },
    { type: "Accesories", category: "Mens Accessories ", subCategory: "Scarves" },
    { type: "Accesories", category: "Mens Accessories ", subCategory: "Socks" },
    { type: "Accesories", category: "Mens Accessories ", subCategory: "Sunglasses" },
    { type: "Accesories", category: "Mens Accessories ", subCategory: "Watches" },
    { type: "Accesories", category: "Mens Accessories ", subCategory: "Bags" },
    { type: "Accesories", category: "Mens Accessories ", subCategory: "Caps" },
    { type: "Accesories", category: "Mens Accessories ", subCategory: "Hats" },

    { type: "Accesories", category: "Womens Accessories ", subCategory: "Sunglasses" },
    { type: "Accesories", category: "Womens Accessories ", subCategory: "Bags & Purses" },
    { type: "Accesories", category: "Womens Accessories ", subCategory: "Watches" },
    { type: "Accesories", category: "Womens Accessories ", subCategory: "Belts" },
    { type: "Accesories", category: "Womens Accessories ", subCategory: "Hair Accessories" },
    { type: "Accesories", category: "Womens Accessories ", subCategory: "Hats" },
    { type: "Accesories", category: "Womens Accessories ", subCategory: "Scarves" },
    { type: "Accesories", category: "Womens Accessories ", subCategory: "Socks & Tights" },
    { type: "Accesories", category: "Womens Accessories ", subCategory: "Gloves" },
    { type: "Accesories", category: "Womens Accessories ", subCategory: "Hats" },

    { type: "Jewellery", category: "Necklaces" },
    { type: "Jewellery", category: "Necklaces" },
    { type: "Jewellery", category: "Pins" },
    { type: "Jewellery", category: "Body Jewellery" },
    { type: "Jewellery", category: "Bracelets" },
    { type: "Jewellery", category: "Earrings" },
    { type: "Jewellery", category: "Rings" },
    { type: "Jewellery", category: "Watches" },
    { type: "Jewellery", category: "Other" },

    { type: "Beauty", category: "Makeup" },
    { type: "Beauty", category: "Tools & Brushes" },
    { type: "Beauty", category: "Body & Bath" },
    { type: "Beauty", category: "Fragrance" },
    { type: "Beauty", category: "Hair" },
    { type: "Beauty", category: "Skincare" },

    { type: "More", category: "Art" },
    { type: "More", category: "Home" },
    { type: "More", category: "Sports Equipment" },
    { type: "More", category: "Books & Magazines" },
    { type: "More", category: "Tech" },
    { type: "More", category: "Collectables" },

];

export const getProductTypes = () => [...new Set(productTypes.map(item => item.type))];

export const getProductTypesForFilter = () => [...new Set(productTypes.map(item => item.type))]
    .map(item => ({ option: item, label: item }));

export const getCategoriesForProductType = (type) => {
    return [...new Set(productTypes
        .filter(item => item.type === type)
        .map(item => item.category)
        .filter(item => item !== undefined)
    )];
};

export const getCategoriesForProductTypeFilter = (type) => {
    return getCategoriesForProductType(type)
        .map(item => ({ option: item, label: item }));
};

export const getSubcategoriesForProductType = (type, category) => {
    return [...new Set(productTypes
        .filter(item => item.type === type && item.category === category)
        .map(item => item.subCategory)
        .filter(item => item !== undefined)
    )];
}

export const getSubcategoriesForProductTypeFilter = (type, category) => {
    return getSubcategoriesForProductType(type, category)
        .map(item => ({ option: item, label: item }));
};

export const getSecondarySubcategoriesForProductType = (type, category, subCategory) => {
    return [...new Set(productTypes
        .filter(item => item.type === type && item.category === category && item.subCategory === subCategory)
        .map(item => item.secondarySubCategory)
        .filter(item => item !== undefined)
    )];
}
