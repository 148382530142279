import React from "react";
import classNames from "classnames";

import Button from "../Button/Button";
import NamedLink from "../NamedLink/NamedLink";
import { FormattedMessage } from '../../util/reactIntl';

import css from './NamedLinkWithArrow.module.css';

const NamedLinkWithArrow = props => {
    const { name, to, content, className, buttonClassName } = props;

    return (
        <NamedLink className={classNames(css.root, className)} name={name} to={to}>
            <Button className={classNames(css.button, buttonClassName)}>
                <FormattedMessage id={content} />
                <svg width="7" height="8" viewBox="0 0 7 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M6.5 4L0.5 8L0.5 0L6.5 4Z" fill="var(--marketplaceColor)" />
                </svg>
            </Button>
        </NamedLink>
    );
};

export default NamedLinkWithArrow;