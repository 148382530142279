import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import css from './PhotosDropZone.module.css';
import { FormattedMessage } from 'react-intl';

function PhotosDropZone(props) {
    const { form, onImageUploadHandler } = props;
    const onDrop = useCallback(acceptedFiles => {
        acceptedFiles.map(file => {
            form.change(`addImage`, file);
            form.blur(`addImage`);
            onImageUploadHandler(file);
        });
    }, []);

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        accept: {
            'image/jpeg': [],
            'image/png': [],
            'image/jpg': [],
        },
        onDrop,
    });

    return (
        <div className={css.root} {...getRootProps()}>
            <input {...getInputProps()} />
            {isDragActive ? (
                <p>Drop the files here ...</p>
            ) : (
                <div className={css.button}>
                    <FormattedMessage id="PhotosDropZone.uploadPhotos" />
                </div>
            )}
        </div>
    );
}

export default PhotosDropZone;